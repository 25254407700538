<template>
  <v-sheet class="customer">
    <v-card-text class="px-0">
      <v-col md="12" class="px-0 pb-0 d-flex align-center justify-space-between">
        <div>
          <!-- {{ searchParam }} -->
          <h2 class="custom-header-blue-text m-0">Adjustments</h2>
          <!-- <pre>{{ stock }}</pre> -->
          <!-- {{ totalPages }} -->
        </div>
        <div>
          <v-btn
            color="blue darken-4"
            depressed
            tile
            class="white--text"
            :to="{ name: 'Add Adjustment', query: { t: new Date().getTime() } }"
          >
            <!-- :to="{ name: 'Count Stock', query: { t: new Date().getTime() } }" -->
            <v-icon left> mdi-plus </v-icon>
            New Adjustment
          </v-btn>
        </div>
      </v-col>
    </v-card-text>
    <v-simple-table fixed-header class="bt-table table_height_supplier">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="heading in suppliertableHeading"
              :key="`_head_${heading.title}`"
              style="background-color: #f5f5f5"
            >
              <div v-if="heading.status">
                <span v-if="heading.title === '#'">
                  <v-checkbox
                    hide-details
                    color="#0d47a1"
                    class="m-0"
                    v-model="supplier_selectedAll"
                  ></v-checkbox>
                </span>
                <span v-else> {{ heading.title }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="!pageLoading">
          <template v-if="stock?.length">
            <tr
              v-for="(row, index) in stock"
              :key="`$_${index + index * 5}_supplier_` + index"
              :class="index % 2 === 0 && 'blue lighten-5'"
              class="product-listing-tr"
              v-on:click="routeToDetailSupplier(row)"
            >
              <td
                v-for="(th, index) in suppliertableHeading"
                :key="'key_' + index"
                class="cursor-pointer"
              >
                <v-chip
                  color="#4CAF50"
                  v-if="getColValue(th.title) === 'dates' && th.status"
                  style="color: white; font-weight: 600"
                >
                  {{ row.id }}
                </v-chip>

                <div v-else-if="getColValue(th.key) === 'type' && th.status">
                  <v-chip
                    v-if="row.document_Type == 'stock_transfer'"
                    class="py-0"
                    small
                    outlined
                    color="primary"
                    label
                  >
                    Stock Transfer
                  </v-chip>
                  <v-chip v-else class="py-0" small outlined color="primary" label>
                    Stock Adjustment
                  </v-chip>
                </div>
                <div v-else-if="getColValue(th.key) === 'reference' && th.status">
                  <p v-if="row.refrence" class="text-capitalize">{{ row.refrence }}</p>
                  <p v-else class="text-capitalize">No Reference</p>
                </div>

                <div v-else-if="getColValue(th.key) === 'date' && th.status">
                  {{ formatedateTimes(row.date) }}
                </div>
                <div v-else-if="getColValue(th.key) === 'statuss' && th.status">
                  <v-chip
                    v-if="row.status == 'Draft'"
                    color="cyan"
                    outlined
                    small
                    style="font-weight: 600"
                    label
                  >
                    <ValueTemplate title="Status" :value="row.status"></ValueTemplate>
                  </v-chip>
                  <v-chip v-else color="green" outlined small style="font-weight: 600" label>
                    <ValueTemplate title="Status" :value="row.status"></ValueTemplate>
                  </v-chip>
                </div>

                <div
                  v-else-if="getColValue(th.title) === 'created_at' && th.status"
                  link
                  v-on:click="routeToDetailSupplier(row)"
                >
                  <p class="my-auto py-2">
                    <v-chip class="py-0" small outlined color="primary" label>
                      <ValueTemplate
                        :value="row?.added_by?.display_name"
                        title="Admin"
                      ></ValueTemplate>
                    </v-chip>
                  </p>
                  <p>
                    <v-chip small label style="font-weight: 600">
                      {{ formatedateTimes(row.added_at) }}
                    </v-chip>
                  </p>
                </div>
                <div
                  v-else-if="getColValue(th.title) === 'last_modified_at' && th.status"
                  link
                  v-on:click="routeToDetailSupplier(row)"
                >
                  <template v-if="row.updated_at">
                    <p>
                      <v-chip small label style="font-weight: 600">
                        {{ formatedateTimes(row.updated_at) }}
                        <!-- {{ row[getColValue(th.title)]?.value[0].created_date }} -->
                      </v-chip>
                    </p>
                  </template>
                  <template v-else>
                    <p style="font-size: 16px; font-weight: 600">no modified</p>
                  </template>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9">
                <p class="m-0 text-center">
                  <img
                    width="30"
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image mr-4"
                  />
                  Uhh... There are no adjustment at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot v-else>
          <tr v-for="idr in 9" :key="`_$idr_key_${idr}`">
            <td v-for="idk in 9" :key="`_$idk_key_${idk}`">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>

    <v-layout v-if="this.stock?.length" class="light-border-top mt-4">
      <v-flex md6>
        <label class="btx-label p-4"
          >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
        >
      </v-flex>
      <!-- {{ pageLimit }} -->
      <v-flex md6>
        <v-pagination
          color="blue darken-4"
          v-model="currentPage"
          :length="pageLimit"
          :total-visible="7"
        ></v-pagination>
      </v-flex>
    </v-layout>
    <Dialog :dialog="dialog" :dialogWidth="900">
      <template v-slot:title>Desctiption</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <img
                width="70"
                height="70"
                src="https://cdn.pixabay.com/photo/2023/07/04/18/10/wreath-8106795_640.png"
              />
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0"><b class="red--text text--lighten-1"></b>{{ text }}</p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn depressed tile @click="dialog = false"> Close </v-btn>
      </template>
    </Dialog>

    <OrderTemplate
      v-model="order"
      :customer-id="customerId"
      :dialog="orderDialog"
      v-on:close="orderDialog = false"
      v-on:save="saveOrder()"
      v-on:collect:voucher="getAvailableVouchers"
      v-on:claim:voucher="getCollectedVouchers"
    >
    </OrderTemplate>
    <CollectTemplate
      :dialog="collectDialog"
      :order-id="cOrderId"
      :order-code="cOrderCode"
      :vouchers="cVouchers"
      v-on:close="vCollected()"
      v-on:collected="vCollected()"
    ></CollectTemplate>
    <DeleteTemplate
      type="Supplier"
      :delete-text="deleteText"
      delete-note=""
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getData()"
    >
    </DeleteTemplate>
    <SearchTemplate
      :dialog="allCollectedDialog"
      v-on:close="allCollectedDialog = false"
    ></SearchTemplate>
    <Dialog dense :dialog="whatsappPromotional" v-if="whatsappPromotional">
      <template v-slot:title> Whatsapp Promotional </template>
      <template v-slot:body>
        <div class="mx-6" fluid>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">Template</label>
            </v-col>
            <v-col md="8">
              <SelectInput
                hide-details
                hideTopMargin
                :loading="pageLoading"
                :disabled="pageLoading"
                item-text="text"
                dense
                item-value="value"
                :items.sync="templateList"
                v-model="whatsapp.template"
              ></SelectInput>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-col>
            <v-col md="8">
              <v-file-input
                v-model="whatsapp.files"
                ref="fileInput"
                hide-details
                id="item-file-upload"
                label="Upload Images"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          @click="whatsappPromotional = false"
        >
          Send
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="whatsappPromotional = false">
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import { GET_ADJUSTMENT } from "@/core/lib/product.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
//   import SupplierHeader from "@/view/components/SupplierHeader";
import moment from "moment-timezone";

import CollectTemplate from "@/view/components/CollectVoucherTemplate";

import SearchTemplate from "@/view/components/SearchAllVouchers";
import ValueTemplate from "@/view/components/ValueTemplate";
// import ImageTemplate from "@/view/components/Image";
import { SearchEventBus } from "@/core/lib/search.lib";
import { formatMoney } from "accounting-js";

import {
  SET_SEARCH_TITLE,
  SET_SEARCH_MODEL,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
import SelectInput from "@/view/components/SelectInput.vue";
//   import AutoCompleteInput from "@/view/components/AutoCompleteInput";
// import { QUERY } from "@/core/services/store/request.module";

export default {
  name: "supplier-listing",
  title: "Listing Supplier",
  data() {
    return {
      text: "Also called a company description, your business description summarizes what your company does, its purpose, and what makes it unique. It's a critical component of your business plan and immediately follows your executive summary",
      dialog: false,
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      whatsapp: {
        files: [],
        template: "first",
      },
      status_list: [],
      searchEnabled: false,
      totalPages: null,
      searchParam: null,
      supplier_selectedAll: false,
      filterType: "all",
      filterDateRange: null,
      speedDial: [],
      whatsappPromotional: false,
      customers: [],
      stock: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      customerId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      suppliertableHeading: [
        // { title: "#",key:"#", status: true },
        { title: "Date", key: "date", status: true },

        { title: "Reference", key: "reference", status: true },
        // { title: "IMAGE", status: true },
        // { title: "CURRENT STOCK",key:"current", status: true },
        { title: "Type", key: "type", status: true },
        // { title: "Serial no",key:"serial", status: true },
        { title: "Status", key: "statuss", status: true },
        { title: "Created At", key: "created_at", status: true },
      ],
      salesSummaryDate: [
        {
          id: 1,
          checkbox_status: false,
          code: "GHI789",
          select: { value: "", key: "SELECT" },
          action: { value: "", key: "ACTION" },
          image: {
            link: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXJzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
          },
          status: {
            value: [
              {
                status_no: "S0018",
                status_active: "ACTIVE",
              },
            ],
            key: "STATUS",
          },
          company_details: {
            value: [
              {
                company_user: "testing enterprises display",
                testing_enterprises: "tesing enterprises",
                company_email: "bthrust@gmail.com",
                company_number: "",
                display_name: "bthrust testing display",
              },
            ],
            key: "COMPANY DETAILS",
          },
          contact_person: {
            value: [
              {
                _primary: "PRIMARY",
                company_title: "bthrush testing display",
                company_email: "bthrust@gmail.com",
                company_number: "+65-75757575",
                oneMore_details: "+1 more",
              },
            ],
            key: "CONTACT PERSON",
          },
          category: { value: "COMPUTERS & ELECTRIONICS", key: "CATEGORY" },
          created_at: {
            value: [
              {
                createdBy_admin: "SUPER ADMIN",
                created_date: "29/08/2023 05:54PM",
                updated_date: "",
                updated_by: "21/02/2023 03:14PM",
                created_by: "29/08/2023 05:54PM",
              },
            ],
            key: "CREATED AT",
          },
          last_modified_at: {
            value: [
              {
                createdBy_admin: "",
                created_date: "",
                updated_date: "21/02/2023 03:14PM",
                updated_by: "21/02/2023 03:14PM",
                created_by: "29/08/2023 05:54PM",
              },
            ],
            key: "LAST MODIFIED AT",
          },
        },
        {
          id: 2,
          checkbox_status: false,
          code: "MNG272",
          select: { value: "", key: "SELECT" },
          action: { value: "", key: "ACTION" },
          image: {
            link: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHVzZXJzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
          },
          status: {
            value: [
              {
                status_no: "S0017",
                status_active: "INACTIVE",
              },
            ],
            key: "STATUS",
          },
          company_details: {
            value: [
              {
                company_user: "HAZEL",
                testing_enterprises: "Tri-Start Industries Pte Ltd",
                company_email: "sup@bt.com",
                company_number: "+65-96535164",
                display_name: "bthrust testing display",
              },
            ],
            key: "COMPANY DETAILS",
          },
          contact_person: {
            value: [
              {
                _primary: "PRIMARY",
                company_title: "bthrush testing display",
                company_email: "bthrust@gmail.com",
                company_number: "+65-98505334",
                oneMore_details: "+1 more",
              },
            ],
            key: "CONTACT PERSON",
          },
          category: { value: "MANUFACTURING, WHOLESALE, DISTRIBUTION", key: "CATEGORY" },
          created_at: {
            value: [
              {
                createdBy_admin: "SUPER ADMIN",
                created_date: "29/08/2023 02:14PM",
                updated_date: "21/02/2023 03:14PM",
                updated_by: "21/02/2023 03:14PM",
                created_by: "29/08/2023 05:54PM",
                created_time: "",
              },
            ],
            key: "CREATED AT",
          },
          last_modified_at: {
            value: [
              {
                createdBy_admin: "SUPER ADMIN",
                created_date: "18/03/2023 12:14PM",
                updated_date: "21/02/2023 03:14PM",
                created_by: "29/08/2023 05:54PM",
                updated_by: "21/02/2023 03:14PM",
              },
            ],
            key: "LAST MODIFIED AT",
          },
        },
      ],
      dragStartIndex: null,
      dragOverIndex: null,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    supplier_selectedAll(val) {
      this.salesSummaryDate.map((item) => (item.checkbox_status = val));
    },
    suppliertableHeading: {
      handler: function (newval) {
        console.log(newval);
      },
      deep: true,
    },
    currentPage() {
      this.getData();
    },
    listingSearch() {
      this.getData();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        // this.getSummary();
      }
    },
  },
  methods: {
    async getData(search = null) {
      try {
        this.pageLoading = true;
        const { data, totalPages, showingFrom, showingTo, totalrows } = await GET_ADJUSTMENT(
          this.currentPage,
          search
        );
        this.stock = data;
        console.log(data, "rows");
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalrows;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },

    //         getData(search=null) {
    //       this.$store
    //         .dispatch(QUERY, {
    //           url: "adjustment",
    //           data: {
    // current_page: this.currentPage,
    //                       search
    //                     },
    //         })
    //         .then((data) => {
    //           this.stock = data.rows;
    //           console.log(this.stock,'sfsfdf')
    //           this.currentPage = data.currentPage;
    //                     this.showingFrom = data.showingFrom;
    //                     this.showingTo = data.showingTo;
    //                     this.totalRows = data.totalrows;
    //                     console.log(this.totalRows,'gg')
    //                     this.totalPages = data.totalPages;
    //         })
    //         .catch((error) => {
    //           this.logError(error);
    //         })
    //         .finally(() => {
    //           this.timerLoading = false;
    //         });
    //     },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
    // updateAddressPerson(param) {
    //   this.selectstatus = param;
    //   this.getSummary();
    // },
    // statusSelect(data) {
    //   this.selectstatus = data;
    //   this.getSummary();
    // },
    getAvatarColor(value) {
      if (value && value == "all") {
        return "#0d47a1";
      } else if (value && value == "active") {
        return "green";
      } else if (value && value == "inactive") {
        return "red";
      }
    },
    getBgColor(value) {
      if (value && value == "all") {
        return "#90caf9";
      } else if (value && value == "active") {
        return "#6da16e";
      } else if (value && value == "inactive") {
        return "#c78a8a";
      }
    },
    search() {
      this.filterType = this.filter;
      this.filter_by_month = this.month;
      this.getData();
    },
    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.suppliertableHeading[this.dragStartIndex];
      this.suppliertableHeading.splice(this.dragStartIndex, 1);
      this.suppliertableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getSummary();
    },
    saveOrder() {
      this.customerId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.customerId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "supplier-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    // routeToDetailSupplier(id) {}
    routeToDetailSupplier(row) {
      this.$router.push({
        name: "adjustment-detail",
        params: { id: row.id, row: row },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ code, id }) {
      // const delete_row = this.salesSummaryDate[0].code;
      // console.log(delete_row, "delete_row");
      this.deleteText = code;
      this.deleteEndpoint = `supplier/delete/${id}`;
      this.deleteDialog = true;
    },

    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {
          this.$store.dispatch(SET_SEARCH_MODEL, null);
        });
    },
    // async getTemplateListing() {
    //   try {
    //     this.pageLoading = true;
    //     console.log("getTemplateListing");
    //     const data = await GET_TEMPLATE_LIST();
    //     console.log("data", data);
    //   } catch (error) {
    //     this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
    //   } finally {
    //     this.pageLoading = false;
    //   }
    // },
  },
  async mounted() {
    let _this = this;
    SearchEventBus.$on("start-search", (argument) => {
      _this.searchEnabled = false;
      _this.searchParam = argument;
      if (_this.searchParam) {
        _this.searchEnabled = true;
      }
      this.getData(argument);
    });
    SearchEventBus.$emit("search-template", true);
    // this.getSummary();
    this.getData();

    // await this.getTemplateListing();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Adjustments");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    ValueTemplate,
    DeleteTemplate,
    OrderTemplate,
    CollectTemplate,
    SearchTemplate,
    SelectInput,
    Dialog,
    //   SupplierHeader,
    //   AutoCompleteInput
  },
  computed: {
    ...mapGetters(["listingSearch"]),
  },
};
</script>

<style>
.v-image.v-responsive.white.lighten-2.theme--light.border-light-grey.max-width-100px {
  height: 58px;
  width: 70px;
}

.advace_list_menu {
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.158);
  display: flex;
  justify-content: space-between;
}

.table_height_supplier .v-data-table__wrapper {
  height: calc(100vh - 265px);
}

::-webkit-scrollbar {
  height: 10px;
}

.product-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.product-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
</style>
